import React from "react";
import {
  ProjectTimelineContainerElement,
  LineElement,
  TimelineHolderElement,
  TimelineContainerElement
} from "./styled";
import { Item } from "./Item/Item";
import {
  WorkflowType,
  ProjectWorkflowStateType,
} from "../../context/WorkflowsManager/WorkflowsManager";
import { Typography } from "@material-ui/core";

type ProjectTimelineProps = {
  workflow: WorkflowType;
  projectWorkflowStates?: ProjectWorkflowStateType[];
  updateProjectState: (accepted: boolean) => {};
};

export const ProjectTimeline: React.FC<ProjectTimelineProps> = ({
  workflow,
  projectWorkflowStates,
  updateProjectState,
}) => {
  return (
    <ProjectTimelineContainerElement>
      <Typography variant="h6">{workflow.description} Workflow</Typography>
      <TimelineContainerElement>
        <TimelineHolderElement>
          <LineElement />
          {workflow.items?.map((i, idx) => (
            <Item
              updateProjectState={updateProjectState}
              projectWorkflowState={
                projectWorkflowStates
                  ? projectWorkflowStates[
                      projectWorkflowStates?.findIndex(
                        (s) => s.index === i.index
                      )
                    ]
                  : undefined
              }
              key={idx}
              item={i}
            />
          ))}
        </TimelineHolderElement>
      </TimelineContainerElement>
    </ProjectTimelineContainerElement>
  );
};
