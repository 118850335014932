import styled from "styled-components/macro";

export const ContainerElement = styled.div`
    display: flex;
    padding: 50px;
    flex-direction: column;
    width: calc(100% - 50px);
`;

export const DetailContainerElement = styled.div`
    padding-bottom: 20px;
`;