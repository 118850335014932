import React, { useContext } from "react";
import { WorkflowItemType, WorkflowsContext, WorkflowType } from "../../context/WorkflowsManager/WorkflowsManager";
import { CrudTable, getValueFromCommit } from '../CrudTable/CrudTable';

type WorkflowItemsProps = {
  workflow: WorkflowType;
};

export const WorkflowItems: React.FC<WorkflowItemsProps> = ({ workflow }) => {
  const workflowsContext = useContext(WorkflowsContext);

  const getWorkflowItemRowId = (workflowItem: WorkflowItemType) => workflowItem.workflowItemId!;

  const getChangedWorkflowItem = (changed: any): WorkflowItemType | null => {
    const changedWorkflowItemId = parseInt(Object.keys(changed)[0]);
    let description, index, fallbackIndex, check: any;

    description = getValueFromCommit(changed[changedWorkflowItemId], "description");
    index = getValueFromCommit(changed[changedWorkflowItemId], "index");
    fallbackIndex = getValueFromCommit(changed[changedWorkflowItemId], "fallbackIndex");
    check = getValueFromCommit(changed[changedWorkflowItemId], "check");

    if (!workflow.items) return null;

    const workflowItem = workflow.items.find(o => o.workflowItemId === changedWorkflowItemId);

    if (!workflowItem) return null;

    const changedWorkflowItem: WorkflowItemType = {
      workflowItemId: changedWorkflowItemId,
      workflowId: workflow.workflowId!,
      description: description ? description : workflowItem.description,
      index: index ? parseInt(index) : workflowItem.index,
      fallbackIndex: check === "Ja" ? fallbackIndex ? parseInt(fallbackIndex) : workflowItem.fallbackIndex : null,
      check: check ? check === "Ja" ? true : false : workflowItem.check
    };
    
    return changedWorkflowItem;
  }

  const getAddedWorkflowItem = (added: any): WorkflowItemType => {
    const description = getValueFromCommit(added, "description");
    const index = getValueFromCommit(added, "index");
    const fallbackIndex = getValueFromCommit(added, "fallbackIndex");
    const check = getValueFromCommit(added, "check");

    const addedWorkflowItem = {
      workflowId: workflow.workflowId!,
      description: description,
      index: index ? parseInt(index) : 0,
      fallbackIndex: fallbackIndex ? parseInt(fallbackIndex) : null,
      check: check ? check === "Ja" ? true : false : false
    } as WorkflowItemType;

    return addedWorkflowItem;
  }

  return (
    <CrudTable
      columns={[
        { name: 'description', title: 'Beschrijving' },
        { name: 'index', title: 'Nummer' },
        { name: 'fallbackIndex', title: 'Terugvalnummer' },
        { name: 'check', title: 'Controle' }
      ]}
      booleanColumns={["check"]}
      editSelectionColumns={[{
        name: "check",
        id: "",
        data: ["Ja", "Nee"]?.map((w, idx) => ({
          id: idx,
          value: w,
        })),
      }]}
      canAdd
      canDelete
      canEdit
      rows={workflow.items ? workflow.items : []}
      editObject={workflowsContext.editItem}
      addObject={workflowsContext.addItem}
      deleteObject={workflowsContext.deleteItem}
      getChangedObject={getChangedWorkflowItem}
      getAddedObject={getAddedWorkflowItem}
      getRowId={getWorkflowItemRowId}
    />
  );
};

