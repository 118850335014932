import styled from "styled-components/macro";

export const ProjectTimelineContainerElement = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 25px;
  border: 1px solid #e6e6e6;
`;

export const TimelineContainerElement = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  justify-content: center;
  margin-bottom: 30px;
  padding-bottom: 45px;
  background: white;
`;

export const LineElement = styled.div`
  position: absolute;
  height: 3px;
  border-top: dashed 3px #cfcfcf;
  top: 117px;
  width: calc(100% - 135px);
`;

export const TimelineHolderElement = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: center;
`;
