import React from "react";
import { useLocation, useHistory, Switch } from "react-router-dom";

type PageManagerProps = {
  children: React.ReactNode;
};

type PageManagerState = {
  changePage: (path: string) => any;
};

const defaultPage = {
  changePage: () => {
    throw new Error("Context not initialized.");
  },
};

export const PageContext = React.createContext<PageManagerState>(defaultPage);

export const PageManager: React.FC<PageManagerProps> = ({ children }) => {
  let history = useHistory();
  let location = useLocation();

  const changePage = (path: string) => {
    history.push({
      pathname: path,
    });
  };

  return (
    <PageContext.Provider
      value={{
        changePage: changePage,
      }}
    >
      <Switch location={location}>{children}</Switch>
    </PageContext.Provider>
  );
};
