import React, { useState } from "react";
import { fetchAdd, fetchDelete, fetchEdit, fetchGetItems } from "../../utils/CrudApiActions/CrudApiActions";

type ProjectsManagerProps = {
  children: React.ReactNode;
};

type ProjectsManagerState = {
  projects: ProjectType[] | null;
  get: () => Promise<boolean>;
  deleteProject: (projectId: number) => Promise<boolean>;
  deleteOption: (projectOptionId: number) => Promise<boolean>;
  add: (project: ProjectType) => Promise<boolean>;
  addOption: (option: ProjectOptionType) => Promise<boolean>;
  edit: (project: ProjectType) => Promise<boolean>;
  editOption: (projectOption: ProjectOptionType) => Promise<boolean>;
};

export type ProjectType = {
  [state: string]: any;
  projectId: number | null;
  workflowId: number;
  workflowDescription?: string;
  approved: boolean;
  quotationNumber: string;
  created: Date;
  projectOptions?: ProjectOptionType[];
};

export type ProjectOptionType = {
  [state: string]: any;
  projectOptionId: number | null;
  unitOptionId: number;
  projectId: number;
  value: number;
};

const defaultprojects = {
  projects: null,
  get: () => {
    throw new Error("Context not initialized.");
  },
  deleteProject: () => {
    throw new Error("Context not initialized.");
  },
  deleteOption: () => {
    throw new Error("Context not initialized.");
  },
  add: () => {
    throw new Error("Context not initialized.");
  },
  addOption: () => {
    throw new Error("Context not initialized.");
  },
  edit: () => {
    throw new Error("Context not initialized.");
  },
  editOption: () => {
    throw new Error("Context not initialized.");
  }
};

export const ProjectsContext = React.createContext<ProjectsManagerState>(
  defaultprojects
);

export const ProjectsManager: React.FC<ProjectsManagerProps> = ({
  children
}) => {
  const [projectsState, setprojectsState] = useState<ProjectType[] | null>(null);
  const projectsApiPath = "projects";
  const projectOptionsApiPath = "projectOptions";

  const get = async (): Promise<boolean> => {
    const getResult = await fetchGetItems(projectsApiPath);

    if (!getResult) return false;

    setprojectsState(getResult);
    
    return true;
  }

  const deleteProject = async (projectId: number): Promise<boolean> => {
    const deleteprojectResponse = await fetchDelete(projectId, projectsApiPath);

    if (!deleteprojectResponse) return false;

    const copyOfprojectsState: ProjectType[] = Object.assign([], projectsState);
    const projectIndex = copyOfprojectsState.findIndex(u => u.projectId === projectId);
    copyOfprojectsState.splice(projectIndex, 1);

    setprojectsState(copyOfprojectsState);

    return false;
  }

  const add = async (project: ProjectType): Promise<boolean> => {
    const addResult = await fetchAdd(project, projectsApiPath);

    if (!addResult) return false;

    setprojectsState(projectsState ? [...projectsState, addResult] : [addResult]);

    return true;
  }

  const edit = async (project: ProjectType): Promise<boolean> => {
    const editResult = await fetchEdit(project, `${projectsApiPath}/${project.projectId}`);

    if (!editResult) return false;

    const copyOfprojects: ProjectType[] = Object.assign([], projectsState);
    const projectIndex = copyOfprojects.findIndex(u => u.projectId === project.projectId);

    project.projectOptions = copyOfprojects[projectIndex].projectOptions;
    copyOfprojects[projectIndex] = project;

    setprojectsState(copyOfprojects);

    return true;
  }

  const deleteOption = async (projectOptionId: number): Promise<boolean> => {
    const deleteprojectOptionResponse = await fetchDelete(projectOptionId, projectOptionsApiPath);

    if (!deleteprojectOptionResponse) return false;

    const copyOfprojectsState: ProjectType[] = Object.assign([], projectsState);
    const projectId = copyOfprojectsState.find(u => u.projectOptions?.find(o => o.projectOptionId === projectOptionId))?.projectId;
    const projectOptions = copyOfprojectsState?.find(u => u.projectId === projectId)!.projectOptions;
    const projectOptionIndex = projectOptions?.findIndex(o => o.projectOptionId === projectOptionId);

    if (projectOptionIndex === undefined || !projectOptions) return false;

    projectOptions.splice(projectOptionIndex, 1);

    setprojectsState(copyOfprojectsState);

    return false;
  }

  const addOption = async (option: ProjectOptionType): Promise<boolean> => {
    const addOptionResult = await fetchAdd(option, projectOptionsApiPath);

    if (!addOptionResult) return false;

    const copyOfprojectsState = Object.assign([], projectsState) as ProjectType[];
    const project = copyOfprojectsState.find(u => u.projectId === option.projectId);

    // If the project does not have any projectOptions yet,
    // the projectOptions will not exist so initialize it.
    if (!project!.projectOptions) {
      project!.projectOptions = [];
    }

    const projectOptions = copyOfprojectsState?.find(u => u.projectId === option.projectId)!.projectOptions;

    projectOptions?.push(addOptionResult);

    setprojectsState(copyOfprojectsState);

    return true;
  }

  const editOption = async (projectOption: ProjectOptionType): Promise<boolean> => {
    const editResult = await fetchEdit(projectOption, `${projectOptionsApiPath}/${projectOption.projectOptionId}`);

    if (!editResult) return false;

    const copyOfprojects: ProjectType[] = Object.assign([], projectsState);
    const projectOptions = copyOfprojects.find(u => u.projectId === projectOption.projectId)!.projectOptions;

    if (!projectOptions) return false;

    const projectOptionIndex = projectOptions.findIndex(o => o.projectOptionId === projectOption.projectOptionId);
    projectOptions[projectOptionIndex] = projectOption;

    setprojectsState(copyOfprojects);

    return true;
  }

  return (
    <ProjectsContext.Provider
      value={{
        projects: projectsState,
        get: get,
        deleteProject: deleteProject,
        deleteOption: deleteOption,
        add: add,
        addOption: addOption,
        edit: edit,
        editOption: editOption
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
