import Paper from '@material-ui/core/Paper';
import React, { useContext, useEffect } from "react";
import { UnitsContext, UnitType } from "../../context/UnitsManager/UnitsManager";
import { CrudTable, getValueFromCommit } from '../CrudTable/CrudTable';
import { UnitOptions } from '../UnitOptions/UnitOptions';
import { ContainerElement } from "./styled";

type RowDetailProps = {
  row: UnitType;
};

export const Units: React.FC = () => {
  const unitsContext = useContext(UnitsContext);

  useEffect(() => {
    (async () => {
      await unitsContext.get();
    })();
  }, [])

  const getUnitRowId = (unit: UnitType) => unit.unitId!;

  const getAddedUnit = (added: any): UnitType => {
    const description = getValueFromCommit(added, "description");
    const fileName = getValueFromCommit(added, "fileName");

    const addedUnit = {
      description: description,
      fileName: fileName
    } as UnitType;

    return addedUnit;
  }

  const getChangedUnit = (changed: any): UnitType | null => {
    const changedUnitId = parseInt(Object.keys(changed)[0]);
    let description, fileName: string | undefined;

    description = getValueFromCommit(changed[changedUnitId], "description");
    fileName = getValueFromCommit(changed[changedUnitId], "fileName");

    const unit = unitsContext!.units?.find(u => u.unitId === changedUnitId);

    if (!changedUnitId || !unit) return null;

    const changedUnit: UnitType = {
      unitId: changedUnitId,
      description: description ? description : unit.description,
      fileName: fileName ? fileName : unit.fileName
    };

    return changedUnit;
  }

  const RowDetail = (props: RowDetailProps) => {
    const { row: unit } = props;

    return (
      <UnitOptions unit={unit}/>
    )
  }

  return (
    <ContainerElement>
      <Paper>
        <CrudTable
          columns={[
            { name: 'description', title: 'Beschrijving' },
            { name: 'fileName', title: 'Bestandsnaam' }
          ]}
          canAdd
          canDelete
          canEdit
          rows={unitsContext.units ? unitsContext.units : []}
          editObject={unitsContext.edit}
          addObject={unitsContext.add}
          deleteObject={unitsContext.deleteUnit}
          getChangedObject={getChangedUnit}
          getAddedObject={getAddedUnit}
          getRowId={getUnitRowId}
          rowDetail={RowDetail}
        />
      </Paper>
    </ContainerElement>
  );
};

