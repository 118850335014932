import React, { useState } from "react";
import { fetchAdd, fetchDelete, fetchEdit, fetchGetItems, fetchGetItem } from "../../utils/CrudApiActions/CrudApiActions";

type WorkflowsManagerProps = {
  children: React.ReactNode;
};

type WorkflowsManagerState = {
  workflows: WorkflowType[] | null;
  updateProjectState: (projectId: number, request: UpdateProjectWorkflowStateRequestType) => Promise<boolean>;
  get: () => Promise<boolean>;
  getById: (workflowId: number) => Promise<WorkflowType | undefined>;
  getAllByProjectIdProjectStates: (projectId: number) => Promise<ProjectWorkflowStateType[] | undefined>;
  deleteWorkflow: (workflowId: number) => Promise<boolean>;
  deleteItem: (workflowItemId: number) => Promise<boolean>;
  add: (workflow: WorkflowType) => Promise<boolean>;
  addItem: (item: WorkflowItemType) => Promise<boolean>;
  edit: (workflow: WorkflowType) => Promise<boolean>;
  editItem: (workflowItem: WorkflowItemType) => Promise<boolean>;
  getItem: (workflowItemId: number) => Promise<WorkflowItemType | undefined>;
};

export type WorkflowType = {
  [state: string]: any;
  workflowId: number | null;
  description: string;
  items?: WorkflowItemType[];
};

export enum ProjectTimelineItemState {
  Idle = 0,
  Active = 1,
  Done = 2,
  Blocked = 3,
}

export type WorkflowItemType = {
  [state: string]: any;
  workflowId?: number;
  workflowItemId: number | null;
  description: string;
  index: number;
  fallbackIndex: number | null;
  check: boolean;
};

export type ProjectWorkflowStateType = {
  projectWorkflowStatId: number;
  state: ProjectTimelineItemState;
  reason: string;
  index: number;
  fallbackIndex?: number;
  check: boolean;
  description: string;
  projectId: number;
  timeStamp: Date;
  updatedAtTimeStamp: Date;
}

export type UpdateProjectWorkflowStateRequestType = {
  reason?: string;
  workflowId: number;
  accepted: boolean;
}

const defaultWorkflows = {
  workflows: null,
  updateProjectState: () => {
    throw new Error("Context not initialized.");
  },
  get: () => {
    throw new Error("Context not initialized.");
  },
  getById: () => {
    throw new Error("Context not initialized.");
  },
  getAllByProjectIdProjectStates: () => {
    throw new Error("Context not initialized.");
  },
  deleteWorkflow: () => {
    throw new Error("Context not initialized.");
  },
  deleteItem: () => {
    throw new Error("Context not initialized.");
  },
  add: () => {
    throw new Error("Context not initialized.");
  },
  addItem: () => {
    throw new Error("Context not initialized.");
  },
  edit: () => {
    throw new Error("Context not initialized.");
  },
  editItem: () => {
    throw new Error("Context not initialized.");
  },
  getItem: () => {
    throw new Error("Context not initialized.");
  }
};

export const WorkflowsContext = React.createContext<WorkflowsManagerState>(
  defaultWorkflows
);

export const WorkflowsManager: React.FC<WorkflowsManagerProps> = ({
  children
}) => {
  const [workflowsState, setWorkflowsState] = useState<WorkflowType[] | null>(null);
  const WorkflowsApiPath = "Workflows"; 
  const WorkflowItemsApiPath = "WorkflowItems"; 
  const ProjectWorkflowStatesApiPath = "ProjectWorkflowStates"; 

  const updateProjectState = async (projectId: number, request: UpdateProjectWorkflowStateRequestType): Promise<boolean> => {
    const getResult = await fetchEdit(request, `${ProjectWorkflowStatesApiPath}/${projectId}`);

    if (!getResult) return false;

    return true;
  }

  const get = async (): Promise<boolean> => {
    const getResult = await fetchGetItems(WorkflowsApiPath);

    if (!getResult) return false;

    setWorkflowsState(getResult);

    return true;
  }

  const getById = async (id: number): Promise<WorkflowType | undefined> => {
    const getResult: WorkflowType = await fetchGetItem(`${WorkflowsApiPath}/${id}`);

    if (!getResult) return undefined;

    return getResult;
  }

  const getAllByProjectIdProjectStates = async (id: number): Promise<ProjectWorkflowStateType[] | undefined> => {
    
    const getResult = await fetchGetItems(`${ProjectWorkflowStatesApiPath}/${id}`) as ProjectWorkflowStateType[];

    if (!getResult) return undefined;
    
    return getResult;
  }

  const deleteWorkflow = async (workflowId: number): Promise<boolean> => {
    const deleteWorkflowResponse = await fetchDelete(workflowId, WorkflowsApiPath);

    if (!deleteWorkflowResponse) return false;

    const copyOfWorkflowsState: WorkflowType[] = Object.assign([], workflowsState);
    const workflowIndex = copyOfWorkflowsState.findIndex(u => u.workflowId === workflowId);
    copyOfWorkflowsState.splice(workflowIndex, 1);

    setWorkflowsState(copyOfWorkflowsState);

    return false;
  }

  const add = async (workflow: WorkflowType): Promise<boolean> => {
    const addResult = await fetchAdd(workflow, WorkflowsApiPath);
    
    if (!addResult) return false;

    setWorkflowsState(workflowsState ? [...workflowsState, addResult] : [addResult]);

    return true;
  }

  const edit = async (workflow: WorkflowType): Promise<boolean> => {
    const editResult = await fetchEdit(workflow, `${WorkflowsApiPath}/${workflow.workflowId}`);

    if (!editResult) return false;

    const copyOfWorkflows: WorkflowType[] = Object.assign([], workflowsState);
    const workflowIndex = copyOfWorkflows.findIndex(u => u.workflowId === workflow.workflowId);

    workflow.items = copyOfWorkflows[workflowIndex].items;
    copyOfWorkflows[workflowIndex] = workflow;
    
    setWorkflowsState(copyOfWorkflows);

    return true;
  }

  const deleteItem = async (workflowItemId: number): Promise<boolean> => {
    const deleteWorkflowItemResponse = await fetchDelete(workflowItemId, WorkflowItemsApiPath);

    if (!deleteWorkflowItemResponse) return false;
 
    const copyOfWorkflowsState: WorkflowType[] = Object.assign([], workflowsState);
    const workflowId = copyOfWorkflowsState.find(u => u.items?.find(o => o.workflowItemId === workflowItemId))?.workflowId;
    const workflowItems = copyOfWorkflowsState?.find(u => u.workflowId === workflowId)!.items;
    const workflowItemIndex = workflowItems?.findIndex(o => o.workflowItemId === workflowItemId);

    if (workflowItemIndex === undefined || !workflowItems) return false;

    workflowItems.splice(workflowItemIndex, 1);

    setWorkflowsState(copyOfWorkflowsState);

    return false;
  }

  const addItem = async (item: WorkflowItemType): Promise<boolean> => {
    const addItemResult = await fetchAdd(item, WorkflowItemsApiPath);
    
    if (!addItemResult) return false;

    const copyOfWorkflowsState = Object.assign([], workflowsState) as WorkflowType[];
    const workflow = copyOfWorkflowsState.find(u => u.workflowId === item.workflowId);

    // If the workflow does not have any items yet,
    // the items will not exist so initialize it.
    if(!workflow!.items){
      workflow!.items = [];
    }

    const workflowItems = copyOfWorkflowsState?.find(u => u.workflowId === item.workflowId)!.items;
    
    workflowItems?.push(addItemResult);

    setWorkflowsState(copyOfWorkflowsState);

    return true;
  }

  const editItem = async (workflowItem: WorkflowItemType): Promise<boolean> => {
    const editResult = await fetchEdit(workflowItem, `${WorkflowItemsApiPath}/${workflowItem.workflowItemId}`);

    if (!editResult) return false;

    const copyOfWorkflows: WorkflowType[] = Object.assign([], workflowsState);
    const workflowItems = copyOfWorkflows.find(u => u.workflowId === workflowItem.workflowId)!.items;
    
    if (!workflowItems) return false;
    
    const workflowItemIndex = workflowItems.findIndex(o => o.workflowItemId === workflowItem.workflowItemId);
    workflowItems[workflowItemIndex] = workflowItem;

    setWorkflowsState(copyOfWorkflows);

    return true;
  }

  const getItem = async (itemId: number): Promise<WorkflowItemType | undefined> => {
    const getItemResult = await fetchGetItem(`${WorkflowItemsApiPath}/${itemId}`);
    return getItemResult;
  } 

  return (
    <WorkflowsContext.Provider
      value={{
        workflows: workflowsState,
        updateProjectState: updateProjectState,
        get: get,
        getById: getById,
        getAllByProjectIdProjectStates: getAllByProjectIdProjectStates,
        deleteWorkflow: deleteWorkflow,
        deleteItem: deleteItem,
        add: add,
        addItem: addItem,
        edit: edit,
        editItem: editItem,
        getItem: getItem
      }}
    >
      {children}
    </WorkflowsContext.Provider>
  );
};
