import React from "react";
import { ContainerElement } from "./styled";
import { Projects as ProjectsComponent } from "../../general/Projects/Projects";

export const Projects: React.FC = () => {
  return (
    <ContainerElement>
      <ProjectsComponent />
    </ContainerElement>
  );
};
