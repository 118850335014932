import React, { useContext } from "react";
import { UnitOptionType, UnitsContext, UnitType } from "../../context/UnitsManager/UnitsManager";
import { CrudTable, getValueFromCommit } from '../CrudTable/CrudTable';

type UnitOptionsProps = {
  unit: UnitType;
};

export const UnitOptions: React.FC<UnitOptionsProps> = ({ unit }) => {
  const unitsContext = useContext(UnitsContext);

  const getUnitOptionRowId = (unit: UnitOptionType) => unit.unitOptionId!;

  const getChangedUnitOption = (changed: any): UnitOptionType | null => {
    const changedUnitOptionId = parseInt(Object.keys(changed)[0]);
    let description, planeName, min, max: string | undefined;

    description = getValueFromCommit(changed[changedUnitOptionId], "description");
    planeName = getValueFromCommit(changed[changedUnitOptionId], "planeName");
    min = getValueFromCommit(changed[changedUnitOptionId], "min");
    max = getValueFromCommit(changed[changedUnitOptionId], "max");

    if (!unit.options) return null;

    const unitOption = unit.options.find(o => o.unitOptionId === changedUnitOptionId);

    if (!unitOption) return null;

    const changedUnitOption: UnitOptionType = {
      unitOptionId: changedUnitOptionId,
      description: description ? description : unitOption.description,
      planeName: planeName ? planeName : unitOption.planeName,
      min: min ? parseInt(min) : unitOption.min,
      max: max ? parseInt(max) : unitOption.max,
      unitId: unit.unitId!
    };

    return changedUnitOption;
  }

  const getAddedUnitOption = (added: any): UnitOptionType => {
    const description = getValueFromCommit(added, "description");
    const planeName = getValueFromCommit(added, "planeName");
    const min = getValueFromCommit(added, "min");
    const max = getValueFromCommit(added, "max");

    const addedUnitOption = {
      description: description,
      planeName: planeName,
      min: min ? parseInt(min) : 0,
      max: max ? parseInt(max) : 0,
      unitId: unit.unitId!
    } as UnitOptionType;

    return addedUnitOption;
  }

  return (
    <CrudTable
      columns={[
        { name: 'description', title: 'Beschrijving' },
        { name: 'planeName', title: 'Plane naam' },
        { name: 'min', title: 'Minimaal' },
        { name: 'max', title: 'Maximaal' }
      ]}
      canAdd
      canDelete
      canEdit
      rows={unit.options ? unit.options : []}
      editObject={unitsContext.editOption}
      addObject={unitsContext.addOption}
      deleteObject={unitsContext.deleteOption}
      getChangedObject={getChangedUnitOption}
      getAddedObject={getAddedUnitOption}
      getRowId={getUnitOptionRowId}
    />
  );
};

