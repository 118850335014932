import React, { useState } from "react";
import { fetchAdd, fetchDelete, fetchEdit, fetchGetItems, fetchGetItem } from "../../utils/CrudApiActions/CrudApiActions";

type UnitsManagerProps = {
  children: React.ReactNode;
};

type UnitsManagerState = {
  units: UnitType[] | null;
  get: () => Promise<boolean>;
  deleteUnit: (unitId: number) => Promise<boolean>;
  deleteOption: (unitOptionId: number) => Promise<boolean>;
  add: (unit: UnitType) => Promise<boolean>;
  addOption: (option: UnitOptionType) => Promise<boolean>;
  edit: (unit: UnitType) => Promise<boolean>;
  editOption: (unitOption: UnitOptionType) => Promise<boolean>;
  getOption: (unitOptionId: number) => Promise<UnitOptionType | undefined>;
};

export type UnitType = {
  [state: string]: any;
  unitId: number | null;
  description: string;
  fileName: string;
  options?: UnitOptionType[];
};

export type UnitOptionType = {
  [state: string]: any;
  unitOptionId: number | null;
  description: string;
  planeName: string;
  min: number;
  max: number;
  unitId?: number;
};

const defaultUnits = {
  units: null,
  get: () => {
    throw new Error("Context not initialized.");
  },
  deleteUnit: () => {
    throw new Error("Context not initialized.");
  },
  deleteOption: () => {
    throw new Error("Context not initialized.");
  },
  add: () => {
    throw new Error("Context not initialized.");
  },
  addOption: () => {
    throw new Error("Context not initialized.");
  },
  edit: () => {
    throw new Error("Context not initialized.");
  },
  editOption: () => {
    throw new Error("Context not initialized.");
  },
  getOption: () => {
    throw new Error("Context not initialized.");
  }
};

export const UnitsContext = React.createContext<UnitsManagerState>(
  defaultUnits
);

export const UnitsManager: React.FC<UnitsManagerProps> = ({
  children
}) => {
  const [unitsState, setUnitsState] = useState<UnitType[] | null>(null);
  const UnitsApiPath = "Units"; 
  const UnitOptionsApiPath = "UnitOptions"; 

  const get = async (): Promise<boolean> => {
    const getResult = await fetchGetItems(UnitsApiPath);

    if (!getResult) return false;

    setUnitsState(getResult);

    return true;
  }

  const deleteUnit = async (unitId: number): Promise<boolean> => {
    const deleteUnitResponse = await fetchDelete(unitId, UnitsApiPath);

    if (!deleteUnitResponse) return false;

    const copyOfUnitsState: UnitType[] = Object.assign([], unitsState);
    const unitIndex = copyOfUnitsState.findIndex(u => u.unitId === unitId);
    copyOfUnitsState.splice(unitIndex, 1);

    setUnitsState(copyOfUnitsState);

    return false;
  }

  const add = async (unit: UnitType): Promise<boolean> => {
    const addResult = await fetchAdd(unit, UnitsApiPath);

    if (!addResult) return false;

    setUnitsState(unitsState ? [...unitsState, addResult] : [addResult]);

    return true;
  }

  const edit = async (unit: UnitType): Promise<boolean> => {
    const editResult = await fetchEdit(unit, `${UnitsApiPath}/${unit.unitId}`);

    if (!editResult) return false;

    const copyOfUnits: UnitType[] = Object.assign([], unitsState);
    const unitIndex = copyOfUnits.findIndex(u => u.unitId === unit.unitId);

    unit.options = copyOfUnits[unitIndex].options;
    copyOfUnits[unitIndex] = unit;
    
    setUnitsState(copyOfUnits);

    return true;
  }

  const deleteOption = async (unitOptionId: number): Promise<boolean> => {
    const deleteUnitOptionResponse = await fetchDelete(unitOptionId, UnitOptionsApiPath);

    if (!deleteUnitOptionResponse) return false;
 
    const copyOfUnitsState: UnitType[] = Object.assign([], unitsState);
    const unitId = copyOfUnitsState.find(u => u.options?.find(o => o.unitOptionId === unitOptionId))?.unitId;
    const unitOptions = copyOfUnitsState?.find(u => u.unitId === unitId)!.options;
    const unitOptionIndex = unitOptions?.findIndex(o => o.unitOptionId === unitOptionId);

    if (unitOptionIndex === undefined || !unitOptions) return false;

    unitOptions.splice(unitOptionIndex, 1);

    setUnitsState(copyOfUnitsState);

    return false;
  }

  const addOption = async (option: UnitOptionType): Promise<boolean> => {
    const addOptionResult = await fetchAdd(option, UnitOptionsApiPath);

    if (!addOptionResult) return false;

    const copyOfUnitsState = Object.assign([], unitsState) as UnitType[];
    const unit = copyOfUnitsState.find(u => u.unitId === option.unitId);

    // If the unit does not have any options yet,
    // the options will not exist so initialize it.
    if(!unit!.options){
      unit!.options = [];
    }

    const unitOptions = copyOfUnitsState?.find(u => u.unitId === option.unitId)!.options;
    
    unitOptions?.push(addOptionResult);

    setUnitsState(copyOfUnitsState);

    return true;
  }

  const editOption = async (unitOption: UnitOptionType): Promise<boolean> => {
    const editResult = await fetchEdit(unitOption, `${UnitOptionsApiPath}/${unitOption.unitOptionId}`);

    if (!editResult) return false;

    const copyOfUnits: UnitType[] = Object.assign([], unitsState);
    const unitOptions = copyOfUnits.find(u => u.unitId === unitOption.unitId)!.options;
    
    if (!unitOptions) return false;
    
    const unitOptionIndex = unitOptions.findIndex(o => o.unitOptionId === unitOption.unitOptionId);
    unitOptions[unitOptionIndex] = unitOption;

    setUnitsState(copyOfUnits);

    return true;
  }

  const getOption = async (optionId: number): Promise<UnitOptionType | undefined> => {
    const getOptionResult = await fetchGetItem(`${UnitOptionsApiPath}/${optionId}`);
    return getOptionResult;
  } 

  return (
    <UnitsContext.Provider
      value={{
        units: unitsState,
        get: get,
        deleteUnit: deleteUnit,
        deleteOption: deleteOption,
        add: add,
        addOption: addOption,
        edit: edit,
        editOption: editOption,
        getOption: getOption
      }}
    >
      {children}
    </UnitsContext.Provider>
  );
};
