import React from "react";
import { ContainerElement } from "./styled";
import { PartsStructure } from "../../general/PartsStructure/PartsStructure";

export const Parts: React.FC = () => {
  return (
    <ContainerElement>
      <PartsStructure />
    </ContainerElement>
  );
};
