import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import IconButton from '@material-ui/core/IconButton';
import { Close, Done } from '@material-ui/icons';
import React from "react";
import { ProjectTimelineItemState, ProjectWorkflowStateType, WorkflowItemType } from '../../../context/WorkflowsManager/WorkflowsManager';
import { ButtonContainerElement, FallbackLineContainerElement, FallbackLineElement, ItemContainerElement, ItemDescriptionContainerElement, ItemElement, ItemInformationElement } from "./styled";
import { Tooltip } from '@material-ui/core';

type ItemProps = {
  item: WorkflowItemType;
  projectWorkflowState?: ProjectWorkflowStateType;
  updateProjectState: (accepted: boolean) => {};
};

export const Item: React.FC<ItemProps> = ({ item, projectWorkflowState, updateProjectState}) => {
  const colors = ["#CFCFCF", "#FF9800", "#4CAF50", "#F44336"];
  const daysText = ["vandaag", "gisteren", "eergisteren"];
  const daysDiff = projectWorkflowState ? Math.round((new Date().getTime() - new Date(projectWorkflowState.timeStamp.toString()).getTime())/(1000*60*60*24)) : null;

  return (
    <ItemContainerElement>
      {(item.fallbackIndex !== null && item.fallbackIndex !== undefined) && (projectWorkflowState?.state === ProjectTimelineItemState.Blocked) && (
        <FallbackLineContainerElement difference={item.index - item.fallbackIndex}>
          <FallbackLineElement difference={item.index - item.fallbackIndex} />
        </FallbackLineContainerElement>
      )}
      <Tooltip disableHoverListener={!projectWorkflowState?.reason} placement="top" title={`${projectWorkflowState?.reason}`} arrow>
        <ItemElement stateColor={projectWorkflowState?.state ? colors[parseInt(projectWorkflowState.state.toString())] : colors[0]}></ItemElement>
      </Tooltip>
      <ItemDescriptionContainerElement>
        <ItemInformationElement >{projectWorkflowState ? projectWorkflowState.description : item.description}</ItemInformationElement> 
        <ItemInformationElement fontSize={.65}>{daysDiff !== null ?  daysDiff < 3 ? daysText[daysDiff] : `${daysDiff} ${daysDiff === 1 ? 'dag' : 'dagen'} geleden` : '-'}</ItemInformationElement>
      </ItemDescriptionContainerElement>
      {projectWorkflowState?.state === ProjectTimelineItemState.Active &&
        <ButtonContainerElement>
          <IconButton onClick={() => updateProjectState(true)}>
            <Done style={{ color: green[500] }}/>
          </IconButton>
          <IconButton disabled={!projectWorkflowState.check} onClick={() => updateProjectState(false)}>
            <Close style={{ color: projectWorkflowState.check ? red[500] : grey[500] }}/>
          </IconButton>
        </ButtonContainerElement>
      }
    </ItemContainerElement>
  );
};
