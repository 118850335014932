import { DialogContentText, FormControlLabel, Switch } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import React, { useContext, useEffect } from "react";
import {
  ProjectsContext,
  ProjectType,
} from "../../context/ProjectsManager/ProjectsManager";
import {
  ProjectWorkflowStateType,
  UpdateProjectWorkflowStateRequestType,
  WorkflowsContext,
  WorkflowType,
} from "../../context/WorkflowsManager/WorkflowsManager";
import { CrudTable, getValueFromCommit } from "../CrudTable/CrudTable";
import { ProjectOptions } from "../ProjectOptions/ProjectOptions";
import { ProjectTimeline } from "../ProjectTimeline/ProjectTimeline";
import { ContainerElement } from "./styled";

type RowDetailProps = {
  row: ProjectType;
};

const RowDetail = (props: RowDetailProps) => {
  const { row: project } = props;
  const projectsContext = useContext(ProjectsContext);
  const workflowsContext = useContext(WorkflowsContext);
  const [reasonPopupOpen, setReasonPopupOpen] = React.useState(false);
  const [updatedStateAccepted, setUpdatedStateAccepted] = React.useState(false);
  const [reasonText, setReasonText] = React.useState("");

  const [
    projectApprovedSwitchValue,
    setProjectApprovedSwitchValue,
  ] = React.useState<boolean>(project.approved);

  const [workflow, setWorkflow] = React.useState<WorkflowType | null>(null);

  const [projectWorkflowStates, setProjectWorkflowStates] = React.useState<
    ProjectWorkflowStateType[] | undefined
  >(undefined);

  useEffect(() => {
    (async () => {
      const workflowData = await workflowsContext.getById(project.workflowId);
      if (workflowData) {
        setWorkflow(workflowData);
      }
      const projectWorkflowStatesData = await workflowsContext.getAllByProjectIdProjectStates(
        project.projectId!
      );
      if (projectWorkflowStatesData) {
        setProjectWorkflowStates(projectWorkflowStatesData);
      }
    })();
  }, []);

  const handleProjectApproveSwitchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProjectApprovedSwitchValue(event.target.checked);

    const changedProject: ProjectType = {
      projectId: project.projectId,
      workflowId: project.workflowId,
      approved: event.target.checked,
      quotationNumber: project.quotationNumber,
      created: project.created,
    };

    await projectsContext.edit(changedProject);
  };

  const updateProjectState = async (accepted: boolean) => {
    setUpdatedStateAccepted(accepted);
    if (!accepted) {
      handleReasonPopupOpen();
    } else {
      if (project.projectId) {
        const request: UpdateProjectWorkflowStateRequestType = {
          workflowId: project.workflowId,
          accepted: updatedStateAccepted,
        };
        await workflowsContext.updateProjectState(project.projectId, request);
        const projectWorkflowStatesData = await workflowsContext.getAllByProjectIdProjectStates(
          project.projectId!
        );
        if (projectWorkflowStatesData) {
          setProjectWorkflowStates(projectWorkflowStatesData);
        }
      }
    }
  };

  const handleReasonPopupClose = () => {
    setReasonText("");
    setUpdatedStateAccepted(false);
    setReasonPopupOpen(false);
  };

  const handleReasonPopupOpen = () => {
    setReasonPopupOpen(true);
  };

  const handleSaveReason = async () => {
    if (project.projectId) {
      const request: UpdateProjectWorkflowStateRequestType = {
        workflowId: project.workflowId,
        reason: reasonText,
        accepted: updatedStateAccepted,
      };
      await workflowsContext.updateProjectState(project.projectId, request);
      const projectWorkflowStatesData = await workflowsContext.getAllByProjectIdProjectStates(
        project.projectId!
      );
      if (projectWorkflowStatesData) {
        setProjectWorkflowStates(projectWorkflowStatesData);
      }
    }
    handleReasonPopupClose();
  };

  const handleReasonValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReasonText(event.target.value);
  };

  return (
    <>
      <Dialog
        open={reasonPopupOpen}
        onClose={handleReasonPopupClose}
        aria-labelledby="form-reason-title"
      >
        <DialogTitle id="form-reason-title">Reden van afkeur</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Geef hier een reden van afkeur zodat de tekening zo snel mogelijk
            aangepast kan worden.
          </DialogContentText>
          <TextField
            value={reasonText}
            onChange={handleReasonValueChange}
            autoFocus
            margin="dense"
            id="name"
            label="Reden"
            type="text"
            multiline
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReasonPopupClose} color="primary">
            Annuleren
          </Button>
          <Button onClick={handleSaveReason} color="primary">
            Opslaan
          </Button>
        </DialogActions>
      </Dialog>
      {workflow && (
        <ProjectTimeline
          updateProjectState={updateProjectState}
          projectWorkflowStates={projectWorkflowStates}
          workflow={workflow}
        />
      )}
      <FormControlLabel
        control={
          <Switch
            checked={projectApprovedSwitchValue}
            onChange={handleProjectApproveSwitchChange}
            color={"primary"}
          />
        }
        label="Goedgekeurd"
      />
      <ProjectOptions project={project} />
    </>
  );
};

export const Projects: React.FC = () => {
  const projectsContext = useContext(ProjectsContext);
  const workflowsContext = useContext(WorkflowsContext);

  useEffect(() => {
    (async () => {
      await projectsContext.get();
      await workflowsContext.get();
    })();
  }, []);

  const getProjectRowId = (project: ProjectType) => project.projectId!;

  const getAddedUnit = (added: any): ProjectType => {
    const quotationNumber = getValueFromCommit(added, "quotationNumber");
    const workflowId = getValueFromCommit(added, "workflowId");

    const addedProject = {
      approved: true,
      workflowId: workflowId ? parseInt(workflowId) : null,
      quotationNumber: quotationNumber,
      created: new Date(),
    } as ProjectType;

    return addedProject;
  };

  const getChangedUnit = (changed: any): ProjectType | null => {
    const changedProjectId = parseInt(Object.keys(changed)[0]);
    let quotationNumber, approved, workflowId: any;

    quotationNumber = getValueFromCommit(
      changed[changedProjectId],
      "quotationNumber"
    );
    approved = getValueFromCommit(changed[changedProjectId], "approved");
    workflowId = getValueFromCommit(changed[changedProjectId], "workflowId");

    const project = projectsContext!.projects?.find(
      (p) => p.projectId === changedProjectId
    );

    if (!changedProjectId || !project) return null;

    const changedProject: ProjectType = {
      projectId: changedProjectId,
      workflowId: workflowId ? workflowId : project.workflowId,
      workflowDescription: workflowId ? workflowsContext.workflows?.find(w => w.workflowId === workflowId)?.description : project.workflowDescription,
      approved: approved ? !!approved : project.approved,
      quotationNumber: quotationNumber
        ? quotationNumber
        : project.quotationNumber,
      created: project.created,
    };

    return changedProject;
  };

  return (
    <ContainerElement>
      <Paper>
        <CrudTable
          search
          columns={[
            { name: "quotationNumber", title: "ProjectNummer" },
            { name: "approved", title: "Goedgekeurd" },
            { name: "workflowDescription", title: "Workflow" },
          ]}
          editSelectionColumns={[
            {
              name: "workflowDescription",
              id: "workflowId",
              data: workflowsContext.workflows?.map((w) => ({
                id: w.workflowId!,
                value: w.description,
              })),
            },
            {
              name: "approved",
              id: "",
              data: ["Ja", "Nee"]?.map((w, idx) => ({
                id: idx,
                value: w,
              })),
            },
          ]}
          booleanColumns={["approved"]}
          canAdd
          canDelete
          canEdit
          rows={projectsContext.projects ? projectsContext.projects : []}
          editObject={projectsContext.edit}
          addObject={projectsContext.add}
          deleteObject={projectsContext.deleteProject}
          getChangedObject={getChangedUnit}
          getAddedObject={getAddedUnit}
          getRowId={getProjectRowId}
          rowDetail={RowDetail}
        />
      </Paper>
    </ContainerElement>
  );
};
