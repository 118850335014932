import authService from '../../api-authorization/AuthorizeService';

export const fetchGetItem = async (apiPath: string): Promise<any | undefined> => {
  const token = await authService.getAccessToken();

  const response = await fetch(`/api/${apiPath}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) return undefined;
  return await response.json();
}

export const fetchGetItems = async (apiPath: string): Promise<any[] | undefined> => {
  const token = await authService.getAccessToken();

  const response = await fetch(`/api/${apiPath}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) return undefined;
  return await response.json();
}

export const fetchDelete = async (itemId: number, apiPath: string): Promise<boolean> => {
  const token = await authService.getAccessToken();

  const response = await fetch(`/api/${apiPath}/${itemId}`, {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });
  if (!response.ok) return false;
  return true;
}

export const fetchAdd = async (item: any, apiPath: string): Promise<any | undefined> => {
  const token = await authService.getAccessToken();

  const response = await fetch(`/api/${apiPath}`, {
    method: "POST",
    body: JSON.stringify(item),
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });
  if (!response.ok) return undefined;
  return await response.json();
}

export const fetchEdit = async (item: any, apiPath: string): Promise<Response | undefined> => {
  const token = await authService.getAccessToken();

  const response = await fetch(`/api/${apiPath}`, {
    method: "PUT",
    body: JSON.stringify(item),
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });
  if (!response.ok) return undefined;
  return await response;
}