import React from "react";
import { ContainerElement } from "./styled";

export const Home: React.FC = () => {
  return (
    <ContainerElement>
      Home
    </ContainerElement>
  );
};
