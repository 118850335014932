import Paper from '@material-ui/core/Paper';
import React, { useContext, useEffect } from "react";
import { WorkflowsContext, WorkflowType } from "../../context/WorkflowsManager/WorkflowsManager";
import { CrudTable, getValueFromCommit } from '../CrudTable/CrudTable';
import { WorkflowItems } from '../WorkflowItems/WorkflowItems';
import { ContainerElement } from "./styled";

type RowDetailProps = {
  row: WorkflowType;
};

export const Workflows: React.FC = () => {
  const workflowsContext = useContext(WorkflowsContext);

  useEffect(() => {
    (async () => {
      await workflowsContext.get();
    })();
  }, [])

  const getWorkflowRowId = (workflow: WorkflowType) => workflow.workflowId!;

  const getAddedWorkflow = (added: any): WorkflowType => {
    const description = getValueFromCommit(added, "description");

    const addedWorkflow = {
      description: description
    } as WorkflowType;

    return addedWorkflow;
  }

  const getChangedWorkflow = (changed: any): WorkflowType | null => {
    const changedWorkflowId = parseInt(Object.keys(changed)[0]);
    let description: string | undefined;

    description = getValueFromCommit(changed[changedWorkflowId], "description");

    const workflow = workflowsContext!.workflows?.find(u => u.workflowId === changedWorkflowId);

    if (!changedWorkflowId || !workflow) return null;

    const changedWorkflow: WorkflowType = {
      workflowId: changedWorkflowId,
      description: description ? description : workflow.description
    };

    return changedWorkflow;
  }

  const RowDetail = (props: RowDetailProps) => {
    const { row: workflow } = props;

    return (
      <WorkflowItems workflow={workflow}/>
    )
  }

  return (
    <ContainerElement>
      <Paper>
        <CrudTable
          columns={[
            { name: 'description', title: 'Beschrijving' }
          ]}
          canAdd
          canDelete
          canEdit
          rows={workflowsContext.workflows ? workflowsContext.workflows : []}
          editObject={workflowsContext.edit}
          addObject={workflowsContext.add}
          deleteObject={workflowsContext.deleteWorkflow}
          getChangedObject={getChangedWorkflow}
          getAddedObject={getAddedWorkflow}
          getRowId={getWorkflowRowId}
          rowDetail={RowDetail}
        />
      </Paper>
    </ContainerElement>
  );
};

