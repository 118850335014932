import styled from "styled-components/macro";

export const ContainerElement = styled.div`
    display: flex;
    padding: 50px;
    flex-direction: column;
`;

export const ButtonContainerElement = styled.div`
    display: flex;
`;
