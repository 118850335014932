import styled from "styled-components/macro";

type ItemContainerProps = {
  stateColor: string;
};

export const ItemContainerElement = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 75px;
`;

export const ItemDescriptionContainerElement = styled.div`
  font-size: 0.8rem;
  margin: 0 auto;
  width: 100%;
  bottom: -10px;
  color: #7a7a7a;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

type ItemInformationElementProps = {
  fontSize?: number;
};

export const ItemInformationElement = styled.p<ItemInformationElementProps>`
  font-size: ${props => props.fontSize ? props.fontSize : '.8'}rem;
  margin-bottom: .2rem;
`;

export const ItemElement = styled.div<ItemContainerProps>`
  display: flex;
  background: ${(props) => props.stateColor};
  border: 3px solid #cfcfcf;
  padding: 15px;
  margin: 25px 50px;
  z-index: 1;
  max-width: 33px;
  max-height: 33px;
  width: 100%;
  height: 100%;
  border-radius: 39px;
`;

type FallbackLineProps = {
  difference: number;
};

export const FallbackLineContainerElement = styled.div<FallbackLineProps>`
  width: ${(props) => props.difference * 100}%;
  left: -${(props) => (props.difference * 100) / 2 + 50 * (props.difference - 1)}%;
  height: ${(props) => props.difference * 25}%;
  overflow: hidden;
  position: absolute;
  top: -${(props) => (props.difference - 1) * 25}%;
`;

export const FallbackLineElement = styled.div<FallbackLineProps>`
  width: 100%;
  top: ${(props) => ((props.difference -1) * 25) + 15}px;
  height: 100%;
  position: absolute;
  border-radius: 100%;
  border: dashed 3px #cfcfcf;
  transform: rotate(180deg);
`;

export const ButtonContainerElement = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
`;
