import React from "react";
import { ContainerElement } from "./styled";
import { Workflows as WorkflowsComponent } from "../../general/Workflows/Workflows";

export const Workflows: React.FC = () => {
  return (
    <ContainerElement>
      <WorkflowsComponent />
    </ContainerElement>
  );
};
