import { ChangeSet, Column, EditingState, RowDetailState, DataTypeProvider, SearchState, IntegratedFiltering, FilteringState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableEditColumn, TableEditRow, TableHeaderRow, TableRowDetail, SearchPanel, Toolbar, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import React, {useState} from "react";
import { Checkbox, Select, Switch } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

type editSelectionDataType = {
  id: number;
  value: any;
}

type editSelectionType = {
  name: string;
  id: string;
  data: editSelectionDataType[] | undefined;
}

type CrudTableProps<T = any> = {
  columns: Column[];
  rows: T[];
  rowDetail?: T;
  booleanColumns?: string[];
  editSelectionColumns?: editSelectionType[];
  canAdd?: boolean;
  canDelete?: boolean;
  canEdit?: boolean;
  search?: boolean;
  editObject: (changedObject: T) => {};
  addObject: (addedObject: T) => {};
  deleteObject: (addedObject: T) => {};
  getChangedObject: (changed: T) => T;
  getAddedObject: (added: T) => T;
  getRowId: (rowObject: T) => number;
};

export const getValueFromCommit = (commit: any, key: string): string | undefined => {
  try {
    return commit[key];
  } catch (e) {
    return undefined;
  }
}

type ColumnSelectionType = {
  name: string;
  value: any;
}

export const CrudTable: React.FC<CrudTableProps> = ({
  columns,
  rows,
  rowDetail,
  booleanColumns,
  editSelectionColumns,
  canAdd,
  canDelete,
  canEdit,
  search,
  editObject,
  deleteObject,
  addObject,
  getRowId,
  getChangedObject,
  getAddedObject
}) => {
  const [editorSelection, setEditorSelection] = useState<ColumnSelectionType[]>();
  const [booleanFilterOperations] = useState(["equal"]);
  const [filteringColumnExtensions] = useState(
    [
      ...(booleanColumns ?? []).map(c => (
        {
          columnName: c,
          predicate: (value: any, row: any) => row.value === "none" ? true : (value ? "Ja" : "Nee") === row.value
        }
      )),
      ...(editSelectionColumns ?? []).map(c => (
        {
          columnName: c.name,
          predicate: (value: any, row: any) => row.value === "none" ? true : value === row.value
        }
      ))
    ]
  );

  const commitChanges = ({ added, changed, deleted }: ChangeSet) => {
    if (changed) {
      const id = Object.keys(changed)[0];

      editorSelection?.forEach(e => {
        const columnIdName = editSelectionColumns?.find(c => e.name === c.name)?.id;
        changed = changed ?  
          {[`${id}`] : {...changed[`${id}`], [`${columnIdName}`] : e.value }} : 
          {[`${id}`] : {[`${columnIdName}`] : e.value }}
      })

      const changedObject = getChangedObject(changed);

      if (!changedObject) return;

      (async () => {
        await editObject(changedObject);
      })();
    }
    if (added) {
      let addedItem = added[0];

      editorSelection?.forEach(e => {
        const columnIdName = editSelectionColumns?.find(c => e.name === c.name)?.id;
        addedItem = {...addedItem, [`${columnIdName}`] : e.value }
      })

      const changedObject = getAddedObject(addedItem);

      if (!changedObject) return;

      (async () => {
        await addObject(changedObject);
      })();
    }
    if (deleted) {
      (async () => {
        for (let id of deleted) {
          if (!window.confirm(`Weet je zeker dat je dit item wilt verwijderen?`)) {
            return;
          } else {
            await deleteObject(parseInt(id.toString()));
          }
        }
      })();
    }
  }

  const BooleanFormatter = ({ value }: any) => <>{value ? "Ja" : "Nee"}</>;

  const handleSelectionEditorColumnChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const copyEditorSelection = Object.assign([], editorSelection) as ColumnSelectionType[];
    const indexOfColumnName = copyEditorSelection.findIndex(s => s.name === event.target.name);
    
    // Column value not set yet.
    if(indexOfColumnName === -1){
      setEditorSelection(editorSelection ? 
        [...editorSelection, {name: event.target.name, value: event.target.value}] as ColumnSelectionType[] : 
        [{name: event.target.name, value: event.target.value}] as ColumnSelectionType[])
    }else{
      copyEditorSelection[indexOfColumnName].value = event.target.value;
      setEditorSelection([...copyEditorSelection, {name: event.target.name, value: event.target.value}] as ColumnSelectionType[])
    }
  }

  const SelectionEditorColumn =  ({ column, onValueChange }: DataTypeProvider.ValueEditorProps) => {
    const selectData = editSelectionColumns?.find(c => column.name === c.name)?.data;

    return (
      <Select
        labelId={`${column.name}-select-label`}
        id={`${column.name}-select`}
        value={editorSelection ? editorSelection.find(s => s.name === column.name)?.value : "none"}
        inputProps={{
          name: column.name,
        }}
        onChange={(e)=> {
          handleSelectionEditorColumnChange(e);
          onValueChange(e.target.value);
        } }
      >
        <MenuItem selected value="none">Geen</MenuItem>
        {selectData?.map(s => (
          <MenuItem value={s.value}>{s.value}</MenuItem>
        ))}
      </Select>
    )
  };
  
  return (
    <>
      <Grid
        rows={rows ? rows : []}
        columns={columns}
        getRowId={getRowId}
      >
        {search && <SearchState />}
        {booleanColumns &&
          <DataTypeProvider
            editorComponent={SelectionEditorColumn}
            formatterComponent={BooleanFormatter}
            availableFilterOperations={booleanFilterOperations}
            for={booleanColumns}
          />
        }
        {editSelectionColumns &&
          <DataTypeProvider
            editorComponent={SelectionEditorColumn}
            for={editSelectionColumns.map(e => e.name)}
          />
        }
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering columnExtensions={filteringColumnExtensions}/>

        <RowDetailState />
        <EditingState
          onCommitChanges={commitChanges}
        />
        <Table />
        {canEdit &&
          <TableEditRow />
        }
        {(canAdd || canDelete || canEdit) &&
          <TableEditColumn
            showAddCommand={canAdd}
            showEditCommand={canEdit}
            showDeleteCommand={canDelete}
          />
        }
        <TableHeaderRow />
        {rowDetail &&
          <TableRowDetail
            contentComponent={rowDetail}
          />
        }
        {search && <Toolbar />}
        {search && <SearchPanel />}
        <TableFilterRow
          showFilterSelector
        />
      </Grid>
    </>
  );
};

