import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { RoutePath } from "../../../data/RoutePath";
import { LoginMenu } from "../../api-authorization/LoginMenu";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    width: 408
  },
  title: {
    paddingLeft: 15,
    paddingTop: 15
  },
  section: {
    marginTop: 20
  },
  closeButton: {
    maxHeight: 48,
    outline: "none !important",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  closeButtonContainer: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "center"
  },
  titleSection: {
    padding: "0, 20px"
  },
  hamburgerButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  headerContainer: {
    zIndex: 999
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  menu: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    background: "white",
    color: "#212529",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  menuBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  hide: {
    display: "none"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  logo: {

  }
}));

const Navigation = props => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  let location = useLocation();
  
  const [version, setVersion] = useState("Versienummer laden...");

  useEffect(() => {
    (async () => {
      const response = await fetch("./version.json", {
        method: "GET",
      });
    
      if (!response.ok) return;
      const data = await response.json();

      setVersion(data.solidWorksConfigVersion);
    })();
  }, [])

  const handleDrawerClose = e => {
    setMenuOpen(false);
  };

  const handleDrawerOpen = e => {
    setMenuOpen(true);
  };

  const handleLinkClick = e => {
    setMenuOpen(false);
  };

  const drawer = (
    <Grid item xs={12}>
      <div className={classes.closeButtonContainer}>
        <img className={classes.logo} src="logo-Ned-Air-rgb.png" height={50} alt="nedair logo" />
        <IconButton className={classes.closeButton} onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List dense={false}>
        <Link
          onClick={handleLinkClick}
          to={RoutePath.Parts}
          style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}
        >
          <ListItem button>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary={"Categorieën"} />
          </ListItem>
        </Link>
        <Link
          onClick={handleLinkClick}
          to={RoutePath.Projects}
          style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}
        >
          <ListItem button>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary={"Projecten"} />
          </ListItem>
        </Link>
        <Link
          onClick={handleLinkClick}
          to={RoutePath.Units}
          style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}
        >
          <ListItem button>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary={"Units"} />
          </ListItem>
        </Link>
        <Link
          onClick={handleLinkClick}
          to={RoutePath.Workflows}
          style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}
        >
          <ListItem button>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary={"Workflows"} />
          </ListItem>
        </Link>
        
        <a href="https://discbv.stackstorage.com/s/YgvrrdnpGM4LcK3">
          <ListItem button>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary={"Download"} secondary={version} />
          </ListItem>
        </a>
        <LoginMenu />
      </List>
    </Grid>
  );
 
  return (
    <div className={classes.headerContainer}>
      <AppBar
        className={clsx(classes.menu, {
          [classes.menuBarShift]: menuOpen
        })}
        position="fixed"
        color="primary"
      >
        <Toolbar>
          <IconButton
            onClick={handleDrawerOpen}
            edge="start"
            color="inherit"
            aria-label="Menu"
            className={clsx(classes.hamburgerButton, menuOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Solidworks / <span style={{ color: "#9e9e9e" }}>{location.pathname.slice(1, location.pathname.length)}</span>
          </Typography>
        </Toolbar>
      </AppBar>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          variant="temporary"
          open={menuOpen}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default Navigation;