import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { fetchAdd, fetchDelete, fetchGetItems, fetchEdit } from "../../utils/CrudApiActions/CrudApiActions";

type CategoriesManagerProps = {
  children: React.ReactNode;
};

type CategoriesManagerState = {
  categories: CategoryType[] | null;
  parts: PartType[] | null;
  getCategoriesAndParts: () => Promise<boolean>;
  setItemsForCategory: (uuid: string) => Promise<boolean>;
  saveParts: (parts: PartType[]) => Promise<boolean>;
  createNewCategory: (category: CategoryType) => Promise<boolean>;
  deleteCategory: (categoryId: number) => Promise<boolean>;
  deletePart: (partId: number) => Promise<boolean>;
  deleteParts: (partIds: number[]) => Promise<boolean>;
  editCategory: (category: CategoryType) => Promise<boolean>;
  searchCategory: (value: string) => Promise<boolean>;
};

export type CategoryType = {
  categories: CategoryType[] | null;
  categoryId: number;
  parentCategoryId: number;
  description: string;
  hasCategories: boolean;
  hasParts: boolean;
  parts: PartType[] | null;
  parentId?: string | null;
  id?: string | null;
};

export type PartType = {
  partId: number;
  partNumber: string;
  description: string;
  categoryId: number;
  width: number;
  height: number;
  length: number;
  material: string;
  parentId?: string | null;
  id?: string | null;
};

const defaultCategories = {
  categories: null,
  parts: null,
  getCategoriesAndParts: () => {
    throw new Error("Context not initialized.");
  },
  setItemsForCategory: () => {
    throw new Error("Context not initialized.");
  },
  saveParts: () => {
    throw new Error("Context not initialized.");
  },
  createNewCategory: () => {
    throw new Error("Context not initialized.");
  },
  deleteCategory: () => {
    throw new Error("Context not initialized.");
  },
  deletePart: () => {
    throw new Error("Context not initialized.");
  },
  deleteParts: () => {
    throw new Error("Context not initialized.");
  },
  editCategory: () => {
    throw new Error("Context not initialized.");
  },
  searchCategory: () => {
    throw new Error("Context not initialized.");
  }
};

export const CategoriesContext = React.createContext<CategoriesManagerState>(
  defaultCategories
);

export const CategoriesManager: React.FC<CategoriesManagerProps> = ({
  children
}) => {
  const [categoriesState, setCategoriesState] = useState<CategoryType[] | null>(null);
  const [partsState, setPartsState] = useState<PartType[] | null>(null);
  const [searchedCategoryState, setSearchedCategoryState] = useState<CategoryType[] | null>(null);
  const [searchedPartsState, setSearchedPartsState] = useState<PartType[] | null>(null);

  /**
   * Fills the state with parts.
   * @param pin Employee pini
   */
  const getCategoriesAndParts = async (): Promise<boolean> => {
    const categories = await fetchGetItems("categories/roots");

    if (!categories) return false;

    categories.forEach(c => {
      c.parentId = null;
      c.id = uuidv4();
    });

    setCategoriesState(categories);
    return true;
  };

  const setItemsForCategory = async (uuid: string): Promise<boolean> => {
    // If the categories are not loaded yet,
    // The category cannot be opened so return false.
    if (!categoriesState) return false;

    const categoryIndex = searchedCategoryState ? searchedCategoryState.findIndex(c => c.id === uuid) : categoriesState.findIndex(c => c.id === uuid);
    const copyOfCategoriesState = Object.assign([], searchedCategoryState ? searchedCategoryState : categoriesState) as CategoryType[];
    const copyOfPartsState = Object.assign([], searchedPartsState ? searchedCategoryState : partsState) as PartType[];

    if (categoryIndex === -1) return false;

    const fetchCategoryResult = await fetchGetItems(`categories/${copyOfCategoriesState[categoryIndex].categoryId}`) as unknown;
    const fetchedCategory = fetchCategoryResult as CategoryType;

    if (!fetchedCategory) return false;

    fetchedCategory?.parts?.forEach(p => {
      p.parentId = uuid;
      p.id = uuidv4();
    });

    fetchedCategory?.categories?.forEach(c => {
      c.parentId = uuid;
      c.id = uuidv4();
    });

    if (fetchedCategory.parts) {
      const updatedParts = [...copyOfPartsState, ...fetchedCategory.parts];
      // Remove duplicates from state.
      const partsWithoutDuplicates = updatedParts.filter((v, i, a) => a.findIndex(t => (t.partId === v.partId)) === i);
      if(searchedPartsState){
        setSearchedPartsState(partsWithoutDuplicates);
      }else{
        setPartsState(partsWithoutDuplicates);
      }
    }

    if (fetchedCategory.categories) {
      const updatedCategories = [...copyOfCategoriesState, ...fetchedCategory.categories];
      // Remove duplicates from state.
      const categoryWithoutDuplicates = updatedCategories.filter((v, i, a) => a.findIndex(t => (t.categoryId === v.categoryId)) === i);
      
      if(searchedCategoryState){
        setSearchedCategoryState(categoryWithoutDuplicates);
      }else{
        setCategoriesState(categoryWithoutDuplicates);
      }
    }

    return true;
  }

  const saveParts = async (parts: PartType[]): Promise<boolean> => {
    const savePartsResults = await fetchAdd(parts, "parts/multiple") as unknown;
    const savePartsResult = savePartsResults as PartType[];
    
    if (!savePartsResult) return false;

    const copyOfCategoriesState: CategoryType[] = Object.assign([], categoriesState);
    const categoryId = parts[0].categoryId;
    const parentCategory = copyOfCategoriesState.find(c => c.categoryId === categoryId);
    parentCategory!.hasParts = true;

    setCategoriesState(copyOfCategoriesState);

    savePartsResult.forEach(p => {
      p.parentId = parentCategory?.id;
      p.id = uuidv4();
    });

    const duplicates = parts.filter(p => !savePartsResult.map(sp => sp.partNumber).includes(p.partNumber)) as PartType[];
    
    if(duplicates.length > 0){
      alert(`De volgende parts bestaan al:${duplicates.map(p => ` ${p.partNumber}`)}`);
    }

    setPartsState(partsState ? [...partsState, ...savePartsResult] : [...savePartsResult]);

    return true;
  }

  const createNewCategory = async (category: CategoryType): Promise<boolean> => {
    const saveCategoryResult = await fetchAdd(category, "categories");

    if (!saveCategoryResult) return false;

    saveCategoryResult.hasCategories = false;
    saveCategoryResult.hasParts = false;
    saveCategoryResult.id = uuidv4();
    saveCategoryResult.parentId = category.parentCategoryId ? categoriesState?.find(c => c.categoryId === category.parentCategoryId)?.id : null;
    setCategoriesState(categoriesState ? [...categoriesState, saveCategoryResult] : [saveCategoryResult]);

    return true;
  }

  const deleteCategory = async (categoryId: number): Promise<boolean> => {
    const deleteResult = await fetchDelete(categoryId, `categories`);

    if (!deleteResult) return false;

    // Remove the item from the state.
    const copyOfCategoriesState: CategoryType[] = Object.assign([], categoriesState);
    const categoryIndex = copyOfCategoriesState.findIndex(c => c.categoryId === categoryId);
    const parentId = copyOfCategoriesState[categoryIndex].parentId;

    copyOfCategoriesState.splice(categoryIndex, 1);

    // If parent category is empty after deleting the category,
    // update the hasCategories bool so it won't show the expand arrow anymore.
    if (parentId) {
      if (copyOfCategoriesState.filter(c => c.parentId === parentId).length < 1) {
        const parentCategory = copyOfCategoriesState.find(c => c.id === parentId);
        parentCategory!.hasCategories = false;
      }
    }

    setCategoriesState(copyOfCategoriesState);

    return true;
  }

  const deletePart = async (partId: number): Promise<boolean> => {
    const deleteResult = await fetchDelete(partId, "parts");

    if (!deleteResult) return false;

    // Remove the item from the state.
    const copyOfPartsState: PartType[] = Object.assign([], partsState);
    const copyOfCategoriesState: CategoryType[] = Object.assign([], categoriesState);
    const partIndex = copyOfPartsState.findIndex(p => p.partId === partId);
    const parentId = copyOfPartsState[partIndex].parentId;

    copyOfPartsState.splice(partIndex, 1);

    // If parent category is empty after deleting the category,
    // update the hasCategories bool so it won't show the expand arrow anymore.
    if (parentId) {
      if (copyOfCategoriesState.filter(c => c.parentId === parentId).length < 1) {
        const parentCategory = copyOfCategoriesState.find(c => c.id === parentId);
        parentCategory!.hasParts = false;
      }
    }

    setPartsState(copyOfPartsState);

    return true;
  }

  const deleteParts = async (partIds: number[]): Promise<boolean> => {
    if (!window.confirm(`Weet je zeker dat je de items wilt verwijderen?`)) {
      return false;
    } else {
      if (!partsState) return false;

      await (async () => {
        for (let id of partIds) {
          await fetchDelete(id, "parts");
        }
      })();

      // Now delete the ids from state.
      const copyOfPartsState: PartType[] = Object.assign(
        [],
        partsState.filter((p) => !partIds.includes(p.partId))
      );
      const copyOfCategoriesState: CategoryType[] = Object.assign(
        [],
        categoriesState
      );

      // If parent category is empty after deleting the category,
      // update the hasCategories bool so it won't show the expand arrow anymore.
      copyOfCategoriesState
        .filter((c) => !copyOfPartsState.map((p) => p.parentId).includes(c.id))
        .forEach((c) => {
          c.hasParts = false;
        });

      setCategoriesState(copyOfCategoriesState);
      setPartsState(copyOfPartsState);
    }

    return true;
  };

  const editCategory = async (category: CategoryType): Promise<boolean> => {
    const editResult = await fetchEdit(category, `categories/${category.categoryId}`);

    if (!editResult) return false;

    const copyOfCategories: CategoryType[] = Object.assign([], categoriesState);
    const categoryIndex = copyOfCategories.findIndex(c => c.categoryId === category.categoryId);
    
    category.categories = copyOfCategories[categoryIndex].categories ? copyOfCategories[categoryIndex].categories : [];
    category.parts = copyOfCategories[categoryIndex].parts ? copyOfCategories[categoryIndex].parts : [];
    category.hasCategories = copyOfCategories[categoryIndex].hasCategories;
    category.hasParts = copyOfCategories[categoryIndex].hasCategories;
    category.id = copyOfCategories[categoryIndex].id;
    category.parentId = copyOfCategories[categoryIndex].parentId;
    
    copyOfCategories[categoryIndex] = category;

    setCategoriesState(copyOfCategories);

    return true;
  }

  const searchCategory = async (value: string): Promise<boolean> => {
    // If search result is empty (user emptied the search field)
    // Then set state to null so the actual categories state can be used again.
    if(value.length < 1){
      setSearchedCategoryState(null);
      return false;
    }

    const searchResult = await fetchGetItems(`categories/search/${value}`);

    // If no results are found set the state to an empty array,
    // so the no data text is shown inside the grid.
    if(!searchResult){
      setSearchedCategoryState([]);
      return false;
    }

    searchResult.forEach(c => {
      c.parentId = null;
      c.id = uuidv4();
    });

    setSearchedCategoryState(searchResult);

    return true;
  }

  return (
    <CategoriesContext.Provider
      value={{
        categories: (searchedCategoryState ? searchedCategoryState : categoriesState) as CategoryType[],
        parts: (searchedPartsState ? searchedPartsState : partsState) as PartType[],
        getCategoriesAndParts: getCategoriesAndParts,
        setItemsForCategory: setItemsForCategory,
        saveParts: saveParts,
        createNewCategory: createNewCategory,
        deleteCategory: deleteCategory,
        deletePart: deletePart,
        deleteParts: deleteParts,
        editCategory: editCategory,
        searchCategory: searchCategory,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};