import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import FileCopy from "@material-ui/icons/FileCopy";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { DropzoneDialog } from "material-ui-dropzone";
import React, { useContext, useState } from "react";
import { CategoriesContext, PartType } from "../../context/CategoriesManager/CategoriesManager";
import { AddCategoryDialog } from "../AddCategoryDialog/AddCategoryDialog";

type CategoryMenuProps = {
  categoryId: number;
  description: string;
};

export const CategoryMenu: React.FC<CategoryMenuProps> = ({ categoryId, description }) => {
  const categoriesContext = useContext(CategoriesContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [dropZoneOpen, setDropZoneOpen] = useState(false);
  const [addCategoryOpen, setAddCategoryOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleImportByFilesClick = () => {
    setDropZoneOpen(true);
  };

  const handleFileDropSave = async (files: File[]) => {
    let parts: PartType[] = [];
    
    files.forEach(async file => {
      const width = parseInt(file.name.split("_")[1]);
      const height = parseInt(file.name.split("_")[2]);
      const length = parseInt(file.name.split("_")[3]);
      let part = {
        partNumber: file.name.split("_")[0],
        width: width ? width : 0,
        height: height ? height : 0,
        length: length ? length : 0,
        description: file.name.split("_")[5],
        material: file.name.split("_")[6].split(".")[0],
        categoryId: categoryId
      } as PartType;

      parts.push(part);
    });
    
    await categoriesContext.saveParts(parts);

    handleFileDropClose();
  };

  const handleFileDropClose = () => {
    setDropZoneOpen(false);
  };

  const handleOpenAddCategoryOpen = () => {
    setAddCategoryOpen(true);
  };

  const handleOpenAddCategoryClose = () => {
    setAddCategoryOpen(false);
  };
  
  return (
    <>
      <AddCategoryDialog
        open={addCategoryOpen}
        categoryDescription={description}
        categoryId={categoryId}
        closePopup={handleOpenAddCategoryClose}
      />
      <DropzoneDialog
        dialogTitle={`Parts toevoegen aan ${description}`}
        open={dropZoneOpen}
        onSave={handleFileDropSave}
        acceptedFiles={[".sldprt"]}
        showPreviews={true}
        maxFileSize={10000000000}
        onClose={handleFileDropClose}
        filesLimit={9999999}
        showAlerts={false}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleImportByFilesClick}>
          <FileCopy fontSize="small" style={{ marginRight: 15, color: "#757575" }} />
          Importeer parts met files
        </MenuItem>
        <MenuItem onClick={handleOpenAddCategoryOpen}>
          <CreateNewFolderIcon fontSize="small" style={{ marginRight: 15, color: "#757575" }} />
          Nieuwe categorie toevoegen
        </MenuItem>
      </Menu>
      <IconButton
        aria-label="add"
        onClick={(e) => handleClick(e)}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
    </>
  );
};

