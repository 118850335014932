import { List, ListItem, ListItemText } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { ProjectOptionType, ProjectType } from "../../context/ProjectsManager/ProjectsManager";
import { UnitOptionType, UnitsContext } from "../../context/UnitsManager/UnitsManager";
import { getValueFromCommit } from '../CrudTable/CrudTable';

type ProjectOptionsProps = {
  project: ProjectType;
};

export const ProjectOptions: React.FC<ProjectOptionsProps> = ({ project }) => {
  const unitsContext = useContext(UnitsContext);
  const [unitOptions, setUnitOptions] = useState<UnitOptionType[] | null>(null);

  useEffect(() => {
    (async () => {
      // Todo: this is quite innefficient but will do for now,
      // make a custom api for getting the information in a project.
      const promises = project.projectOptions?.map(async option => {
        const result = await unitsContext.getOption(option.unitOptionId);
        return result;
      });

      if (!promises) return;

      const tempUnitOptions = await Promise.all(promises);

      setUnitOptions(tempUnitOptions as UnitOptionType[]);
    })();
  }, [])

  // These 3 function below will be used in the future, 
  // when projects van be managed trough the backend and config.
  const getProjectOptionRowId = (project: ProjectOptionType) => project.projectOptionId!;

  const getChangedProjectOption = (changed: any): ProjectOptionType | null => {
    const changedProjectOptionId = parseInt(Object.keys(changed)[0]);
    let value, unitOptionId: string | undefined;

    value = getValueFromCommit(changed[changedProjectOptionId], "value");
    unitOptionId = getValueFromCommit(changed[changedProjectOptionId], "unitOptionId");

    if (!project.projectOptions) return null;

    const projectOption = project.projectOptions.find(o => o.projectOptionId === changedProjectOptionId);

    if (!projectOption) return null;

    const changedProjectOption: ProjectOptionType = {
      projectOptionId: projectOption.projectOptionId,
      value: value ? parseFloat(value.toString()) : projectOption.value,
      unitOptionId: unitOptionId ? parseInt(unitOptionId) : projectOption.unitOptionId,
      projectId: project.projectId!
    };

    return changedProjectOption;
  }

  const getAddedProjectOption = (added: any): ProjectOptionType | null => {
    const value = getValueFromCommit(added, "value");
    const unitOptionId = getValueFromCommit(added, "unitOptionId");

    if (!unitOptionId) return null;

    const addedProjectOption = {
      value: value ? parseFloat(value.toString()) : "",
      unitOptionId: parseInt(unitOptionId),
      projectId: project.projectId!
    } as ProjectOptionType;

    return addedProjectOption;
  }

  return (
    <>
      <List dense style={{ display: "flex" }}>
        {unitOptions && project.projectOptions?.map(p => (
          <ListItem>
            <ListItemText primary={p.value} secondary={unitOptions?.find(o => o.unitOptionId === p.unitOptionId)?.description} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

