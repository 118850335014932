import React from "react";
import { ContainerElement } from "./styled";
import { Units as UnitsComponent } from "../../general/Units/Units";

export const Units: React.FC = () => {
  return (
    <ContainerElement>
      <UnitsComponent />
    </ContainerElement>
  );
};
