import {
  CustomTreeData,
  TreeDataState,
  SortingState,
  Sorting,
  SelectionState,
  SearchState,
  IntegratedSorting
} from "@devexpress/dx-react-grid";
import {
  Grid,
  TableColumnReordering,
  TableFixedColumns,
  TableHeaderRow,
  TableTreeColumn,
  VirtualTable,
  TableSelection,
  Table,
  SearchPanel,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import CategoryIcon from "@material-ui/icons/Category";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FolderIcon from "@material-ui/icons/Folder";
import React, { ReactText, useContext, useEffect, useState } from "react";
import {
  CategoriesContext,
  CategoryType,
} from "../../context/CategoriesManager/CategoriesManager";
import { AddCategoryDialog } from "../AddCategoryDialog/AddCategoryDialog";
import { CategoryMenu } from "../CategoryMenu/CategoryMenu";
import { ContainerElement, ButtonContainerElement } from "./styled";
import EditCategoryDialog from "../EditCategoryDialog";

const useStyles = makeStyles((theme) => ({
  indentComponent: {
    position: "relative",
    "&::after": {
      top: -26,
      right: 13,
      content: '""',
      position: "absolute",
      background: "#e0e0e0",
      width: 1,
      height: 52,
    },
  },
  "@global": {
    "[data-iscategory = 'false'] + * .category-icon": {
      display: "none !important",
    },
    "[data-iscategory = 'true'] + * .category-icon": {
      display: "block !important",
    },
    "[data-iscategory = 'false'] + * .part-icon": {
      display: "block !important",
    },
    "[data-iscategory = 'true'] + * .part-icon": {
      display: "none !important",
    },
  },
  cellComponent: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
  },
  cellButton: {},
  cellButtonsContainer: {},
}));

const getChildRows = (row: any, rootRows: any[]) => {
  const childRows = rootRows.filter(
    (r) => r.parentId === (row ? row.id : null)
  );

  if (childRows.length) return childRows;

  return row && (row.hasParts || row.hasCategories) ? [] : null;
};

const getRowId = (row: any): number => row.id as number;

const TableTreeColumnContentComponent = ({ children }: any) => {
  return (
    <>
      <FolderIcon
        className={"category-icon"}
        style={{ marginRight: 25, color: "#757575" }}
        fontSize="small"
      />
      <CategoryIcon
        className={"part-icon"}
        style={{ marginRight: 25, color: "#757575" }}
        fontSize="small"
      />
      <p style={{ margin: 0 }}>{children}</p>
    </>
  );
};

const TableTreeColumnIndentComponent = ({ level }: any) => {
  const indentStyle = useStyles().indentComponent;
  return (
    <>
      <div
        style={{ width: 25 * level }}
        className={level > 0 ? indentStyle : ""}
      ></div>
    </>
  );
};

const SelectionCellComponent = ({ ...restProps }: any) => {
  return restProps.row.hasCategories !== undefined ? (
    <Table.StubCell data-iscategory={restProps.row.hasCategories !== undefined} {...restProps} />
  ) : (
    <TableSelection.Cell data-iscategory={restProps.row.hasCategories !== undefined} {...restProps} />
  );
};

export const PartsStructure: React.FC = () => {
  const categoriesContext = useContext(CategoriesContext);

  const [columns, setColumns] = useState([
    { name: "description", title: "Description" },
    { name: "width", title: "Breedte" },
    { name: "height", title: "Hoogte" },
    { name: "length", title: "Lengte" },
    { name: "material", title: "Materiaal" },
    { name: "partNumber", title: "Part number" },
    { name: "actions", title: "Actie" },
  ]);
  const [columnOrder, setColumnOrder] = useState([
    "description",
    "width",
    "height",
    "length",
    "material",
    "partNumber",
    "actions",
  ]);
  const [tableColumnExtensions] = useState([
    { columnName: "partNumber", width: 175 },
    { columnName: "width", width: 175 },
    { columnName: "height", width: 175 },
    { columnName: "length", width: 175 },
    { columnName: "material", width: 175 },
    { columnName: "actions", width: 147 },
  ]);
  const [sorting, setSorting] = useState<Array<Sorting>>([
    { columnName: "partNumber", direction: "asc" },
    { columnName: "width", direction: "asc" },
    { columnName: "height", direction: "asc" },
    { columnName: "length", direction: "asc" },
    { columnName: "material", direction: "asc" },
  ]);

  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);
  const [expandedRowIdsPermanent, setExpandedRowIdsPermanent] = useState<
    string[]
  >([]);
  const [rowToEdit, setRowToEdit] = useState<any>({});
  const [editCategoryOpen, setEditCategoryOpen] = useState<boolean>(false);
  const [addCategoryOpen, setAddCategoryOpen] = useState<boolean>(false);
  const [selection, setSelection] = useState<React.ReactText[]>([]);

  const TableTreeColumnCellComponent = ({
    value,
    row,
    column,
    children,
    ...restProps
  }: any) => {
    return (
      <TableTreeColumn.Cell
        className={useStyles().cellComponent}
        {...restProps}
      >
        <div className={useStyles().cellButtonsContainer}>
          {row.hasCategories !== undefined && (
            <>
              <CategoryMenu
                description={row.description}
                categoryId={row.categoryId}
              />
              <IconButton
                aria-label="edit"
                onClick={handleEditCategoryOpen.bind(null, row)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </>
          )}
          <IconButton
            aria-label="delete"
            onClick={
              row.hasCategories !== undefined
                ? handleDeleteCategory.bind(
                    null,
                    row.categoryId,
                    row.description
                  )
                : handleDeletePart.bind(null, row.partId, row.description)
            }
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      </TableTreeColumn.Cell>
    );
  };

  useEffect(() => {
    (async () => {
      await categoriesContext.getCategoriesAndParts();
    })();
  }, []);

  const handleDeleteCategory = (id: number, description: string) => {
    if (
      !window.confirm(`Weet je zeker dat je ${description} wilt verwijderen?`)
    ) {
      return;
    } else categoriesContext.deleteCategory(id);
  };

  const handleDeletePart = (id: number, description: string) => {
    if (
      !window.confirm(`Weet je zeker dat je ${description} wilt verwijderen?`)
    ) {
      return;
    } else categoriesContext.deletePart(id);
  };

  const handleEditCategoryOpen = (row: any) => {
    const category = row as CategoryType;
    setRowToEdit(category);
    setEditCategoryOpen(true);
  };

  const handleEditCategoryClose = () => {
    setEditCategoryOpen(false);
  };

  const onRowExpand = async (rowIds: ReactText[]) => {
    const currentExpandedRowId = rowIds[rowIds.length - 1];

    setExpandedRowIds(rowIds.map((r) => r.toString()));

    if (!expandedRowIdsPermanent.find((r) => r === currentExpandedRowId)) {
      setExpandedRowIdsPermanent([
        ...expandedRowIdsPermanent,
        ...rowIds.map((r) => r.toString()),
      ]);
    }

    // If row has been expanded before don't re-expand (fetch) it again.
    if (
      !currentExpandedRowId ||
      expandedRowIdsPermanent.find((r) => r === currentExpandedRowId)
    )
      return;

    await categoriesContext.setItemsForCategory(
      currentExpandedRowId.toString()
    );
  };

  const handleCloseAddCategory = () => {
    setAddCategoryOpen(false);
  };

  const handleOpenAddCategory = () => {
    setAddCategoryOpen(true);
  };

  const handleDeleteSelection = () => {
    const idsOfPartsToDelete = categoriesContext.parts
      ?.filter((p) => selection.includes(p.id!))
      .map((p) => p.partId);
    if (idsOfPartsToDelete) {
      categoriesContext.deleteParts(idsOfPartsToDelete);
    }
  };

  const handleSearch = (value: string) => {
    categoriesContext.searchCategory(value);
  }

  return (
    <>
      <ContainerElement>
        <ButtonContainerElement>
          <Button
            onClick={handleOpenAddCategory}
            color="primary"
            style={{ maxWidth: 200, margin: 15 }}
          >
            Voeg categorie toe
          </Button>
          {selection.length > 0 && (
            <Button
              onClick={handleDeleteSelection}
              color="primary"
              style={{ maxWidth: 200, margin: 15 }}
            >
              Selectie verwijderen
            </Button>
          )}
        </ButtonContainerElement>
        <AddCategoryDialog
          open={addCategoryOpen}
          closePopup={handleCloseAddCategory}
          isRoot
        />
        <EditCategoryDialog
          category={rowToEdit}
          closePopup={handleEditCategoryClose}
          open={editCategoryOpen}
        />
        <Paper style={{ position: "relative" }}>
          <Grid
            rows={
              categoriesContext.categories
                ? categoriesContext.parts
                  ? [
                      ...categoriesContext.categories,
                      ...categoriesContext.parts,
                    ]
                  : [...categoriesContext.categories]
                : []
            }
            columns={columns}
            getRowId={getRowId}
          >
            <SearchState onValueChange={handleSearch} />
            <TreeDataState
              onExpandedRowIdsChange={onRowExpand}
              expandedRowIds={expandedRowIds}
            />
            <CustomTreeData getChildRows={getChildRows} />
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <SelectionState
              selection={selection}
              onSelectionChange={setSelection}
            />
            <IntegratedSorting/> 
            <VirtualTable
              height="auto"
              columnExtensions={tableColumnExtensions}
            />
            <TableSelection cellComponent={SelectionCellComponent} />
            <TableColumnReordering defaultOrder={columnOrder} />
            <TableHeaderRow showSortingControls />
            <TableTreeColumn
              for="description"
              contentComponent={TableTreeColumnContentComponent}
              indentComponent={TableTreeColumnIndentComponent}
            />
            <TableTreeColumn
              for="actions"
              cellComponent={TableTreeColumnCellComponent}
            />
            <TableFixedColumns rightColumns={["partNumber"]} />
            <Toolbar />
            <SearchPanel />
          </Grid>
        </Paper>
      </ContainerElement>
    </>
  );
};
