import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import React, { useContext, useState } from "react";
import { CategoriesContext, CategoryType } from "../../context/CategoriesManager/CategoriesManager";

type AddCategoryDialogProps = {
  isRoot?: boolean;
  categoryId?: number;
  categoryDescription?: string;
  open: boolean;
  closePopup: () => void;
};

export const AddCategoryDialog: React.FC<AddCategoryDialogProps> = ({ isRoot = false, categoryId, categoryDescription, open, closePopup}) => {
  const categoriesContext = useContext(CategoriesContext);
  const [description, setDescription] = useState<string | null>();

  const handleClose = () => {
    closePopup();
  };

  const descriptionHandleChange = (event: any) => {
    setDescription(event.target.value);
  };

  const handleSaveCategory = async () => {
    const newCategory = {
      description: description,
      parentCategoryId: categoryId
    } as CategoryType;
    
    let saveCategoryResult = await categoriesContext.createNewCategory(newCategory);

    if(!saveCategoryResult) alert("Er ging iets fout tijdens het opslaan van de categorie. Probeer het opnieuw.");

    handleClose();
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {isRoot && "Voeg root categorie toe"}
          {!isRoot && `Voeg categorie toe aan ${categoryDescription}`}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Description"
            type="text"
            fullWidth
            onChange={descriptionHandleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveCategory} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

