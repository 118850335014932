import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { Route } from "react-router-dom";
import { RoutePath } from "../../../data/RoutePath";
import "../../../styles.css";
import { ApplicationPaths } from "../../api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "../../api-authorization/ApiAuthorizationRoutes";
import AuthorizeRoute from "../../api-authorization/AuthorizeRoute";
import { CategoriesManager } from "../../context/CategoriesManager/CategoriesManager";
import { UnitsManager } from "../../context/UnitsManager/UnitsManager";
import { ProjectsManager } from "../../context/ProjectsManager/ProjectsManager";
import { PageManager } from "../../context/PageManager/PageManager";
import { Home } from "../../pages/Home/Home";
import { Parts } from "../../pages/Parts/Parts";
import { Units } from "../../pages/Units/Units";
import { Workflows } from "../../pages/Workflows/Workflows";
import { Projects } from "../../pages/Projects/Projects";
import Navigation from "../Navigation/Navigation";
import { PageContainerElement } from "./styled";
import { WorkflowsManager } from "../../context/WorkflowsManager/WorkflowsManager";

export const App: React.FC = () => {
  return (
    <>
      <Navigation />
      <PageContainerElement>
        <WorkflowsManager>
          <CategoriesManager>
            <UnitsManager>
              <ProjectsManager>
                <PageManager>
                  <AuthorizeRoute
                    path={RoutePath.Home}
                    exact
                    component={Home}
                  />
                  <AuthorizeRoute
                    path={RoutePath.Parts}
                    exact
                    component={Parts}
                  />
                  <AuthorizeRoute
                    path={RoutePath.Projects}
                    exact
                    component={Projects}
                  />
                  <AuthorizeRoute
                    path={RoutePath.Units}
                    exact
                    component={Units}
                  />
                  <AuthorizeRoute
                    path={RoutePath.Workflows}
                    exact
                    component={Workflows}
                  />
                  <Route
                    path={ApplicationPaths.ApiAuthorizationPrefix}
                    component={ApiAuthorizationRoutes}
                  />
                </PageManager>
              </ProjectsManager>
            </UnitsManager>
          </CategoriesManager>
        </WorkflowsManager>
      </PageContainerElement>
    </>
  );
};

export default App;
